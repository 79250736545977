$default-compact-view-breakpoint: 1000px;
$max-width: 1280px;
$element-spacing-small: 16px;
$element-spacing-medium: 24px;
$border-radius-small: 0.25rem;
$border-radius-medium: 0.375rem;
$border-radius-large: 0.5rem;

:root {
    --grid-layout-gutter: #{$element-spacing-medium};
    --grid-layout-margin: #{$element-spacing-medium};
}

@media (max-width: 1000px) {
    :root {
        --grid-layout-gutter: #{$element-spacing-small};
        --grid-layout-margin: #{$element-spacing-small};
    }
}
