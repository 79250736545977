@use 'sass:math';
@use '../../../styles/layout-variables.scss';
@use '../../../styles/colors.scss';
@use '../../../styles/mixins.scss';

.total-amount {
  @include mixins.compact-view() {
    margin-bottom: layout-variables.$element-spacing-medium;
  }
}

.total-amount__amount-container {
  display: flex;
}

.total-amount__value {
  margin: 0 math.div(layout-variables.$element-spacing-small, 4) math.div(layout-variables.$element-spacing-small, 4)
    layout-variables.$element-spacing-small;
  line-height: 1;

  @include mixins.break-word();
}

.total-amount__currency-icon {
  width: 32px;
  height: 32px;
  user-select: none;
}

.total-amount__currency {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  line-height: 1.1;
}

.total-amount__currency-name {
  color: colors.$character-secondary;
}
