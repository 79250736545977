@use '../styles/layout-variables.scss';
@use '../styles/colors.scss';
@use '../styles/mixins.scss';

footer {
  text-align: center;
}

.label {
  font-size: 1rem;
  margin-bottom: layout-variables.$element-spacing-small * 4;

  @include mixins.compact-view() {
    margin-bottom: layout-variables.$element-spacing-small;
  }
}

.warning {
  color: colors.$character-secondary;
  font-size: 12px;
}

.warning__highlight-message {
  color: colors.$character-primary;
}
