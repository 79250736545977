@use 'sass:math';
@use '../../styles/layout-variables.scss';
@use '../../styles/colors.scss';
@use '../../styles/mixins.scss';
@use './ServiceIcon.scss' as service-icon;

.service-info {
  display: grid;
  grid:
    "icon name" auto
    "icon contract" auto
    "description description" auto
    "links links" / (service-icon.$icon-size + math.div(layout-variables.$element-spacing-small, 2)) 1fr;
}

.service-info__name {
  grid-area: name;
  margin: 0 0 math.div(layout-variables.$element-spacing-small, 4) 0;

  @include mixins.break-word();
}

.service-info__icon {
  grid-area: icon;
}

.service-info__contract {
  grid-area: contract;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--grid-layout-margin);
}

.service-info__description {
  grid-area: description;
  margin-bottom: var(--grid-layout-margin);
}

.service-info__links {
  grid-area: links;
}

.contract-address__label {
  color: colors.$character-secondary;
}

.service-link {
  margin-bottom: layout-variables.$element-spacing-small;
}
